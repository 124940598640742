  <!-- Definition Modal -->
  <template>
    <v-dialog max-width="600px" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <v-btn icon v-bind="attrs" v-on="{ ...on, ...tooltipOn }" color="primary" dark>
              <v-icon left>
                mdi-book-open-page-variant
               </v-icon>
            </v-btn>
            </template>
            <span>View Definition</span>
            </v-tooltip>
       </template>
            <v-card>
            <v-card-title class="text-h5">
            <v-icon left>mdi-book-open-page-variant</v-icon>
            Definition
            </v-card-title>
            <v-card-text>{{ definition }}</v-card-text>
            <v-card-actions>
                <v-btn icon @click="onLike">
                    <v-icon>mdi-thumb-up-outline</v-icon>
                </v-btn>
                <v-btn icon @click="onDislike">
                    <v-icon>mdi-thumb-down-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
    definition: {
        type: String,
        default: ''
    }
    },
    data() {
    return {
        dialog: false
    };
    },
    methods: {
    onLike() {
        this.$emit('like');
        this.dialog = false;
    },
    onDislike() {
        this.$emit('dislike');
        this.dialog = false;
    },
    truncate(text, length = 30) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
    },
}
</script>
