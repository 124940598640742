<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Term
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined type="info" dismissible prominent icon="mdi-book-open-page-variant">
          Defining commonly used terms and phrases is essential for maintaining clarity and consistency across your organization. This practice ensures that everyone has a shared understanding of key concepts, leading to more effective communication and collaboration.
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card outlined class="mb-3">
                <!-- Term Text -->
                <v-card-title>
                  {{ item.text }}
                  <v-spacer></v-spacer>
                  <v-chip dark>
                    <v-icon left>mdi-domain</v-icon>
                    {{ item.count }}
                  </v-chip>
                </v-card-title>

                <!-- Discoverable -->
                <v-card-subtitle class="mt-1">
                  <v-icon left color="primary">mdi-eye-check</v-icon>
                  <span>Discoverable: {{ item.discoverable ? 'Yes' : 'No' }}</span>
                </v-card-subtitle>

                <v-card-text>
                  <v-icon left color="purple">mdi-tag-multiple</v-icon>
                  Definitions:
                  <v-chip small v-for="definition in item.definitions" :key="definition.id" class="mr-2 mb-2">
                    <definition-dialog :definition="definition.text" />
                  </v-chip>
                </v-card-text>
                <!-- Actions -->
                <v-card-actions class="justify-end">
                  <v-menu bottom left>
                    <template #activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="createEditShow(item)">
                        <v-list-item-title>View / Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="removeShow(item)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/term/DeleteDialog.vue"
import NewEditSheet from "@/term/NewEditSheet.vue"
import DefinitionDialog from "@/term/DefinitionDialog.vue"

export default {
  name: "TermTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
    DefinitionDialog,
  },
  data() {
    return {
      headers: [],
      selectedDefinition: '',
    }
  },

  computed: {
    ...mapFields("term", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    if (this.query.project) {
      this.project = [{ name: this.query.project }]
    }

    this.getAll()
    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("term", ["getAll", "createEditShow", "removeShow", "showDefinition"]),
  },
}
</script>
